.light{
  --text-color: var(--sauge);
  --title-color:var(--black);
  --bg-color: var(--white);
}

.neutral{
  --text-color: var(--sauge);
  --title-color:var(--black);
  --bg-color: var(--beige);
}

.dark{
  --text-color: var(--white);
  --title-color:var(--white);
  --bg-color: var(--taupe);
}

.container {
  --padding-container: var(--spacing-80);
  display: flex;
  flex-flow: column wrap;
  gap: var(--spacing-8);
  color: var(--text-color);
  padding: var(--padding-container);
}

.subtitle{
  position: relative;
  font: var(--font-h3-mobile-default);
  font-family: var(--font-wix-text);
  font-weight: 800;
  margin-left: 1.6rem;
}

.subtitle::before{
  --size-icon: 12px;
  content: "";
  position: absolute;
  top: 50%;
  left: calc(var(--spacing-24) * -1);
  transform: translateY(-40%);
  height: var(--size-icon);
  width: var(--size-icon);
  background: var(--sauge);
  border-radius: 9rem;
}

.title{
  font: var(--font-h2-desktop-default);
  color: var(--title-color);
  font-weight: 800;
  word-break: break-word;
}

@media (max-width: 1024px) {
  .container{
    --padding-container: var(--spacing-40) var(--spacing-24);
  }
}

@media (max-width: 768px) {
  .title{
    font: var(--font-h2-mobile-default);
    font-family: var(--font-wix-text);
    font-weight: 800;
  }
  .subtitle{
    font: var(--font-p2-mobile-default);
    font-weight: 800;
    margin-left: 2rem;
  }
  .subtitle::before{
    left: -2rem;
  }
}

@media (max-width: 480px) {
  .container{
    --padding-container: var(--spacing-24);
  }
}